<template>
  <div class="common-upload-container flex justify-center items-center">
    <div class="upload-position-container">
      <div class="upload-box upload-single flex justify-center items-center" v-authenticate:IMAGE_COMPRESSOR>
        <commonUpload :fileList="fileList" :setFileList="setFileList" placeHolder="Upload an image to start"
          :multiple="false" :customRequest="customRequest" accept=".JPG,.jpg,.png,.PNG,.webp,.WEBP,.jpeg,.JPEG"
          :limitNum="10" :iconUrl="iconUploadSingle" :iconStyle="{
            width: '46px',
            height: '58px',
            'margin-bottom': '25px',
          }" />
      </div>
      <div class="image-box-background" @mouseenter="handleShowImageList" @mouseleave="handleHiddenImageList">
        <div class="inner-image-box">
          <p class="tooltip-title">No image? Try one of these</p>
          <div class="example-images-box">
            <div class="example-image-icon-box" v-for="item in imagesList" :key="item.id"
              v-authenticate:IMAGE_COMPRESSOR="{ ok: () => handleSelectImage(item) }">
              <div class="mask-item"></div>
              <img :src="item.iconSrc" class="example-image-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineEmits, onMounted } from "vue";
import commonUpload from "@/components/custom-upload/index.vue";
import { getFileFromUrl } from "@/utils/index.js";
import iconUploadSingle from "@/assets/upload-images/icon_upload_single.svg";
import cat_src from '@/assets/cat_src.png'
import { useSideStore, useViewStore } from '@/store/index.js'
const viewStore = useViewStore()
const uploadFileList = ref([]);
const isShowImageList = ref(false);
const imagesList = ref([
  {
    id: 1,
    iconSrc: cat_src,
    src: cat_src,
  },
]);
const preload = () => {
  imagesList.value.forEach(item => {
    const img = new Image();
    img.src = item.iconSrc;
  })
}
onMounted(() => {
  // viewStore.setMinViewWidth('1400px')
  preload();
})
const emits = defineEmits(["uploadComplete", "currentMode", "setLoading"]);

const customRequest = async (file) => {
  const originUrl = URL.createObjectURL(file);
  emits("setLoading", true)
  emits("uploadComplete", { file: file, url: originUrl });
};

const setFileList = async (fileList = []) => {
  const fileArray = fileList.map((file) => file.originFileObj);
  uploadFileList.value = fileArray;
};

const handleShowImageList = () => {
  isShowImageList.value = true;
};

const handleHiddenImageList = () => {
  isShowImageList.value = false;
};

const handleSelectImage = async (item) => {
  const file = await getFileFromUrl(item.src);
  sessionStorage.setItem("sourceImageId", JSON.stringify([`default_image_${item.id}`]));
  customRequest(file);
};
</script>

<style lang="less" scoped>
.common-upload-container {
  min-height: calc(100vh - 60px);
  padding: 60px 0;
  min-width: 1400px;

  .tooltip-card {
    width: 394px;

    left: "280px";
  }

  :global(.ant-tooltip-content) {
    width: 394px;
    height: 100%;
  }

  :global(.ant-tooltip-inner) {
    width: 394px;
    height: 100%;
    color: #8f959e;
  }
}

.upload-box {
  width: 588px;
  height: 196px;
  background: #ffffff;
  border: 1px dashed #d0d0d0;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #f8f5ff;
  }
}

.upload-single {
  margin-right: 34px;
}

.example-images-container {
  width: 394px;

  background-color: green;
}

.example-image-icon {
  height: 110px;
  border-radius: 4px;
}

.mask-item {
  position: absolute;
  width: 164.55px;
  height: 110px;
  border-radius: 4px;
  opacity: 0;
  background-color: #000;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    opacity: 0.3;
  }
}

.example-images-box {
  display: flex;
  justify-content: center;
  padding: 22px 20px;
}

.image-box-background {
  width: 588px;
  height: 230px;
  padding-top: 14px;
}

.inner-image-box {
  width: 588px;
  padding: 0 95px;
}

.upload-position-container {
  position: relative;
}

.example-image-icon-box {
  cursor: pointer;
  position: relative;
  background-color: #F6F6F6;
  width: 164.55px;
  height: 110px;
  border-radius: 4px
}
</style>
